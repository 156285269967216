import axios from 'axios';
import { API_URL } from './constants';

const instance = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default instance;
