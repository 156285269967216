import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../axiosConfig';
import { RoomType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const {
    user,
    roomType,
    setRoomType,
    setDateFormat,
    setAcceptance,
    setRoomStatus,
    setDomainName,
    setIsVideoCreated,
    setBackgroundStyle,
    setCompanyName,
    setMediaPath,
    setIsOwner,
    setTextColor,
  } = useAppState();
  const location = useLocation();
  console.log('location', location);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log('searchParams', searchParams);
    const token = searchParams.get('t');
    console.log('token', token);

    axiosInstance.post('get-room-details', { token: token }).then(response => {
      let name = response.data.data.contact.first_name + ' ' + response.data.data.contact.last_name;
      setName(name);
      setRoomName(response.data.data.room_name);
      setRoomType(response.data.data.room_type);
      setDateFormat(response.data.data.start_datetime);
      setAcceptance(response.data.data.has_accepted);
      setRoomStatus(response.data.data.room_status);
      setIsOwner(response.data.data.is_owner);

      const domain = response.data.data.domain;
      const isVideoCreated = response.data.data.is_video_create;
      setDomainName(domain);
      setIsVideoCreated(isVideoCreated);

      axiosInstance.get('get-video-setting/' + domain).then(response => {
        setBackgroundStyle({
          backgroundColor: response.data.data.color,
        });

        setTextColor(response.data.data.text_color);

        setCompanyName(response.data.data.company_name);

        if (response.data.data.video.media) {
          setMediaPath(response.data.data.video.media);
        } else {
          setMediaPath(response.data.data.logo_url);
        }
      });
      submitRef.current?.click();
    });
  }, [location]);

  const submitRef = useRef<HTMLButtonElement>(null);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1">
        {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
                disabled
              />
            </div>
          )}
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
              disabled
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
            ref={submitRef}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
