import React from 'react';

export default function FullScreen() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33 6H42V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M42 33V42H33" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 42H6V33" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 15V6H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
