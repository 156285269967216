import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase/auth';
import axiosInstance from '../axiosConfig';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  setIsFetching(isFetching: boolean): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  setRoomType(roomType: any): void;
  domainName: string;
  setDomainName(name: string | null): void;
  isVideoCreated: string;
  setIsVideoCreated(name: string | null): void;
  dateFormat: string;
  chatSid: string;
  setDateFormat(name: string | null): void;
  acceptance: string;
  setAcceptance(acceptance: string | null): void;
  roomStatus: string;
  setRoomStatus(name: string | null): void;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  selectedImage: string;
  setSelectedImage(selectedImage: string): void;
  backgroundImagesList: Object;
  setBackgroundImagesList(backgroundImagesList: object): void;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  setBackgroundStyle(backgroundStyle: object | null): void;
  backgroundStyle: object;
  setCompanyName(companyName: string | null): void;
  companyName: string;
  mediaPath: string;
  setMediaPath(mediaPath: string | null): void;
  setIsOwner(mediaPath: string | null): void;
  isOwner: string;
  textColor: string;
  setTextColor(textColor: string | null): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [domainName, setDomainName] = useState<string | null>();
  const [isVideoCreated, setIsVideoCreated] = useState<string | null>();
  const [dateFormat, setDateFormat] = useState<string | null>();
  const [chatSid, setChatSid] = useState<string | null>();
  const [acceptance, setAcceptance] = useState<string | null>();
  const [roomStatus, setRoomStatus] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<string>('blur');
  const [backgroundImagesList, setBackgroundImagesList] = useState<object | null>({});
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);

  const [companyName, setCompanyName] = useState<string | null>();
  const [backgroundStyle, setBackgroundStyle] = useState<object | null>({});
  // const [logo, setLogo] = useState({ logo: '' });
  const [mediaPath, setMediaPath] = useState<string | null>();
  const [isOwner, setIsOwner] = useState<string | null>();
  const [textColor, setTextColor] = useState<string | null>();

  let contextValue = {
    error,
    setError,
    isFetching,
    setIsFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    domainName,
    setDomainName,
    isVideoCreated,
    setIsVideoCreated,
    setRoomType,
    setDateFormat,
    dateFormat,
    roomType,
    chatSid,
    acceptance,
    setAcceptance,
    selectedImage,
    setSelectedImage,
    backgroundImagesList,
    setBackgroundImagesList,
    roomStatus,
    setRoomStatus,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled,
    setBackgroundStyle,
    backgroundStyle,
    setCompanyName,
    companyName,
    mediaPath,
    setMediaPath,
    setIsOwner,
    isOwner,
    textColor,
    setTextColor,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const params = new window.URLSearchParams(window.location.search);

        const token = params.get('t');

        return axiosInstance
          .post('get-token', {
            room_token: token,
          })
          .then(res => {
            setChatSid(res.data.data.chat_sid);
            return {
              token: res.data.data.token,
              room_type: 'group',
            };
          });
      },
      updateRecordingRules: async (room_sid, rules) => {
        const params = new window.URLSearchParams(window.location.search);

        const token = params.get('t');

        const headers = {
          'Content-Type': 'application/json',
        };

        return axiosInstance
          .post(
            'update-recording-rules',
            {
              room_sid: room_sid,
              rules: rules,
              token: token,
            },
            {
              headers: headers,
            }
          )
          .then(res => {
            return res.data;
          })
          .catch(err => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        // setRoomType(res.room_type); // is present in new updates
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
